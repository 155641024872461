<template>
  <v-toolbar
    flat
    dense
    eagle-list="toolbar"
    :style="{ zIndex: $zIndexConfig.listInfo }"
  >
    <!-- 資料筆數 -->
    <v-chip
      v-if="pager"
      label
      color="grey darken-1 white--text"
      class="mr-2"
    >
      <span>{{ 'pager.page'|t({ page: pager.page, pages: pager.pages }) }}</span>
      <span>({{ 'pager.total'|t({ total: dataTotalQuantity }) }})</span>
    </v-chip>

    <!-- 每頁筆數 -->
    <div class="mx-2" style="width: 100px">
      <v-select
        outlined
        hide-details
        dense
        :label="$t('list.per_page')"
        :items="perPageOptions"
        v-model="perPage"
      ></v-select>
    </div>

    <!-- 排序 -->
    <listSort
      class="mr-2"
      :list-key="listKey"
    ></listSort>
  </v-toolbar>
</template>

<script>
export default {
  props: {
    listKey: String,
  },
  computed: {
    dataTotalQuantity() {
      return this.$store.getters[`list/${this.listKey}/dataTotalQuantity`]
    },
    pager() {
      return this.$store.getters[`list/${this.listKey}/pager`]
    },
    listConfig() {
      return this.$store.getters[`list/${this.listKey}/config`]
    },
    perPageOptions() {
      return this.$store.getters[`list/${this.listKey}/perPageOptions`]
    },
    perPage: {
      get() {
        return this.$store.getters[`list/${this.listKey}/perPage`]
      },
      set(value) {
        this.$store.dispatch(`list/${this.listKey}/set`, {
          key: 'perPage',
          value,
        })

        // 更換每頁筆數後從第一頁開始
        this.$store.dispatch(`list/${this.listKey}/set`, {
          key: 'page',
          value: 1,
        })

        this.listActions.changeIndexCondition()
      },
    },
    listActions() {
      return this.$store.getters[`list/${this.listKey}/actions`]
    },
  },
  components: {
    listSort: () => import('@/components/list/listInfo/listSort.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped>
*[eagle-list="toolbar"]
  user-select: none
</style>
